<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="container">
        <div class="security-border">
          <img alt="shield" src="@/assets/icons/security-shield.svg" />
        </div>
        <div class="title">
          Пройдите двухфакторную <br />
          аутентификацию
        </div>
        <div class="description">
          Мы отправили на ваш Telegram аккаунт сообщение с секретным кодом,
          введите его в поле ниже.
        </div>

        <div class="inputs">
          <div class="item" v-for="i in 5" :key="i">
            <input type="text" ref="input" v-model="code[i]" />
          </div>
        </div>

        <button @click="send">Отправить код</button>

        <div class="alert">
          <img alt="2fa" src="@/assets/icons/2fa-close.svg" />
          <div class="info">
            <span>Внимание !</span> Никому никогда не сообщайте код в целях
            безопастности своего аккаунта.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      code: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    getCode() {
      return Object.values(this.code).join("");
    },
  },
  created() {
    if (this.isLoggedIn) return this.$router.push("/");
  },
  methods: {
    async send() {
      const code = this.getCode;
      try {
        let { data } = await this.$http.post("user/2fa", { code });
        if (data.error) return this.$toast.error(data.error);
        const { user } = data.response;
        this.$store.commit("user/authenticate", {
          access_token: localStorage.getItem("access_token"),
          user,
        });
        this.$router.push("/");
      } catch (error) {
        if (error?.status === 401) return this.$router.push("/");
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
  },
  watch: {
    getCode(value) {
      const ref = this.$refs.input;
      if (value === "") return ref[0].focus();
      this.code = {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      };
      const code = value.slice(0, 5).split("");
      code.forEach((n, i) => (this.code[i + 1] = n));
      let length = code.length;
      if (length > 5) length = 5;
      ref[length - 1].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f6fa;
  display: table;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 800px;
      height: 620px;
      margin: 0px auto;
      background: #ffffff;
      box-shadow: 0px 65px 145px #dcdfec;
      border-radius: 24px;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      padding: 50px;
      .security-border {
        width: 120px;
        height: 120px;
        background: #0085ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        margin-bottom: 20px;
      }

      .title {
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        margin-bottom: 25px;
      }

      .description {
        color: #69748a;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
      }

      button {
        width: 220px;
        height: 60px;
        background: #0085ff;
        box-shadow: 0px 15px 15px rgba(0, 133, 255, 0.25);
        border-radius: 6px;
        // font-weight: bold;
        font-size: 18px;
        color: white;
        margin-bottom: 50px;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }

      .alert {
        display: flex;
        align-items: center;
        width: 380px;
        img {
          margin-right: 10px;
        }
        .info {
          font-size: 15px;
          font-weight: 500;
          color: #8d98b0;
          span {
            color: #ff6666;
          }
        }
      }

      .inputs {
        display: flex;
        margin: 30px 0;
        .item {
          width: 50px;
          height: 50px;
          position: relative;
          margin-right: 20px;
          input {
            width: 50px;
            height: 45px;
            text-align: center;
            font-size: 25px;
            font-weight: 600;
          }
          &:last-child {
            margin-right: 0px;
          }
          &::after {
            content: "";
            background: #e4e7f2;
            border-radius: 100px;
            position: absolute;
            bottom: 0;
            display: block;
            left: 0;
            right: 0;
            height: 5px;
          }
          &:focus-within::after {
            background-color: #0085ff;
          }
        }
      }
    }
  }
}
</style>
